export function scrollMenu() {
	const scrollMenus = document.querySelectorAll('[data-scroll-menu]')
	if (scrollMenus.length === 0) return

	scrollMenus.forEach(scrollMenu => {
		const scrollMenuActiveItem = scrollMenu.querySelector('.active')
		if (!scrollMenuActiveItem) return

		scrollMenu.scrollTo({
			left: scrollMenuActiveItem.offsetLeft,
		})
	})
}
