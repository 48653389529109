export function header() {
	const headerEl = document.querySelector('.site-header')
	const announcementEl = document.querySelector('[data-announcement]')

	function updateHeaderClass() {
		const scrollPosition = window.scrollY
		if (scrollPosition > 0) {
			headerEl.classList.replace('site-header--top', 'site-header--not-top')
		} else {
			headerEl.classList.replace('site-header--not-top', 'site-header--top')
		}
	}

	if (document.readyState === 'interactive') updateHeaderClass()

	window.addEventListener('scroll', updateHeaderClass)

	announcement()

	function announcement() {
		if (!announcementEl) return

		const heroSection = document.getElementById('site-content')
		let announcementHeight

		const resizeObserver = new ResizeObserver(entries => {
			for (let entry of entries) {
				announcementHeight = entry.target.offsetHeight
			}
			updateHeaderPosition()
			updateHeroSectionHeight()
		})

		const updateHeaderPosition = () => {
			const scrolled = window.scrollY
			const headerTopOffset = Math.max(0, announcementHeight - scrolled)
			headerEl.style.top = `${headerTopOffset}px`
		}

		const updateHeroSectionHeight = () => {
			if (heroSection && heroSection.children.length > 0) {
				const firstElement = heroSection.children[0]

				if (firstElement.classList.contains('min-height-full')) {
					firstElement.style.minHeight = `calc(100vh - ${announcementHeight}px)`
				}
			}
		}

		resizeObserver.observe(announcementEl)
		window.addEventListener('scroll', updateHeaderPosition)
	}
}
