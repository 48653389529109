export function overlayMenu() {
	let overlayMenuOpen = false
	function overlayMenuHandler() {
		if (overlayMenuOpen) {
			overlayMenuOpen = false
		} else {
			overlayMenuOpen = true
		}

		const overlayMenuVisibleClass = 'overlay-menu--visible'
		const overlayMenuChangingClass = 'overlay-menu--changing'

		const overlayMenu = document.querySelector('.overlay-menu')

		document.documentElement.classList.toggle('is-locked')
		overlayMenu.classList.toggle(overlayMenuVisibleClass)
		overlayMenu.classList.add(overlayMenuChangingClass)

		overlayMenu.addEventListener('transitionend', function () {
			overlayMenu.classList.remove(overlayMenuChangingClass)
		})
	}

	//Elements clicks
	for (const element of document.querySelectorAll('[data-overlay-menu-toggler]')) {
		element.addEventListener('click', function () {
			overlayMenuHandler()
		})
	}

	//Escape click
	document.addEventListener('keydown', function (e) {
		if (overlayMenuOpen && e.key == 'Escape') {
			overlayMenuHandler()
		}
	})

	const targetLinks = document.querySelectorAll('.overlay-menu .menu-item-object-custom a[href*="/#"]')
	targetLinks.forEach(targetLink => {
		targetLink.addEventListener('click', () => {
			overlayMenuHandler()
		})
	})
}
