export function animate() {
	const regularEls = document.querySelectorAll('[data-animate]:not([data-animate-no-margin])')
	const noMarginEls = document.querySelectorAll('[data-animate][data-animate-no-margin]')

	const regularObserver = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add('animate-show')
					regularObserver.unobserve(entry.target)
				}
			})
		},
		{
			rootMargin: '-50px',
		}
	)

	regularEls.forEach(el => {
		regularObserver.observe(el)
	})

	const noMarginObserver = new IntersectionObserver(
		entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add('animate-show')
					noMarginObserver.unobserve(entry.target)
				}
			})
		},
		{
			rootMargin: '0px',
		}
	)

	noMarginEls.forEach(el => {
		noMarginObserver.observe(el)
	})
}
